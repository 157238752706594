/* Global Styles */
:root {
  --primary-color: #723d84; /*17a2b8*/
  --dark-color: #121212;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #a2dda5;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
}

.bg {
  background-color: #84848407;
}

/* Forms styles */
textarea {
  color: black;
}

input:active,
input:focus,
textarea:active,
textarea:focus,
button:active,
button:focus {
  color: black;
  outline: none;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
}

/* end of form styles */

body {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}
.dark {
  background: var(--dark-color);
  color: #fff;
}

.hidden {
  display: none;
}
.visible {
  display: block;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

/* img {
  width: 100%;
} */
.post-image {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 1440px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-content-between {
  display: flex;
  justify-content: space-between;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}
.px {
  padding: 0 0.5rem;
}

.px-1 {
  padding: 0 1rem;
}

.px-2 {
  padding: 0 2rem;
}

.px-3 {
  padding: 0 3rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 3rem;
}

/* Margin */
.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.m {
  margin: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mx {
  margin: 0 0.5rem;
}

.mx-1 {
  margin: 0 1rem;
}

.mx-2 {
  margin: 0 2rem;
}

.mx-3 {
  margin: 0 3rem;
}

.wrap-text-break {
  word-wrap: break-word;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 2.5px;
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  position: absolute;
  left: 80%;
  top: 5%;
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
  z-index: 10;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
  border-radius: 5px;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
  border-radius: 5px;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
  border-radius: 5px;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color) !important;
  color: #fff;
  border-radius: 5px;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
  border-radius: 5px;
}

.btn:hover,
.a:hover,
i:hover {
  opacity: 0.8;
}
i {
  color: var(--primary-color);
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
  object-fit: cover;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form-show {
  display: flex;
  flex-direction: column;
}

.form-hidden {
  display: none;
}

.post-form-heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.8rem 1.4rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.form-toggle {
  border: none;
}
.form-toggle i {
  font-size: 1rem;
  color: white;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type="text"],
.form input[type="url"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form input[type="file"],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.form input[type="file"] {
  background: white; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin: 0.5em 0em;
  border: 1px solid var(--primary-color);
}

.form input[type="submit"],
button {
  background-color: var(--primary-color);
  font: inherit;
}
.form input[type="submit"] {
  width: 33.333%;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}

.form .social-input i.fa-facebook {
  color: #3b5998;
}

.form .social-input i.fa-instagram {
  color: #3f729b;
}

.form .social-input i.fa-youtube {
  color: #c4302b;
}

.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.rules {
  position: absolute;
  bottom: 20vh;
  width: 100%;
  padding: 0;
  border-radius: 0px;

  column-count: 4;
  column-rule-style: solid;
  column-rule-color: #723d84;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

.outline-light {
  border: 2px solid #cccccc1f;
  border-radius: 5px;
  background: transparent;
}

.outline-primary {
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  background: transparent;
}
.outline-danger {
  border: 2px solid var(--danger-color);
  color: var(--danger-color);
  border-radius: 5px;
  background: transparent;
}
.outline-danger > i {
  color: var(--danger-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  /* border-bottom: solid 1px var(--primary-color); */
  /* opacity: 0.9; */
  border-radius: 0 0 0px 0px;
}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-menu {
  display: flex;
}
.navbar-menu > li {
  margin-bottom: 1rem;
}

.navbar-menu > li > a {
  color: white;
  padding: 0.8rem;
  white-space: nowrap;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.logo {
  height: 5rem;
  width: auto;
}

.btn-toggle-menu {
  color: var(--primary-color);
  background: transparent;
  border: none;
  padding: 0.2rem 0.5rem;
  font-size: 2rem;
}
.btn-toggle-menu:active,
.btn-toggle-menu:focus {
  outline: none;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
}

/* Footer */
.footer-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0.75em;
  border-radius: 0px;
}

.footer {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer > a {
  padding: 0 0.5em;
}
.footer > a > i {
  font-size: 1.5em;
}

/* Landing Page */
.landing {
  position: relative;
  background: url("./img/showcase.jpg") no-repeat center center/cover;
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    "top top"
    "about about"
    "posts-heading posts-heading"
    "posts posts"
    "no-memes no-memes";
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  padding: 1rem;
}

.user-information-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding-left: 2rem;
}
.avatar-profile {
  border-radius: 10px;
  width: 50%;
  object-fit: cover;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .favourite {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.posts-heading {
  grid-area: posts-heading;
}

.posts {
  grid-area: posts;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.no-memes {
  grid-area: no-memes;
}

/* Posts Page */

.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  border-radius: 5px;
  background-color: #84848407;
  /* border: #cccccc1f solid 2px; */
  width: 30%;
}

.post-image {
  max-height: 1000px;
  max-width: 500px;
}

.post-actions {
  display: flex;
  flex-wrap: wrap;
}

.post a,
.post button {
  /* margin: 1em; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 35%;
}

.post-head-wrapper {
  display: inline-flex;
  width: 100%;
  margin-bottom: 1rem;
}
.post-head-wrapper a {
  justify-content: start;
  width: 75px;
}

.post > div:first-child {
  text-align: left;
}

.avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.post .comment-count {
  background: var(--primary-color);
  color: var(--light-color);
  margin: 0 1rem;
  padding: 0.1rem 0.3rem;
  border-radius: 3px;
  font-size: 0.8rem;
}

.post .post-date,
.post-hashtags {
  color: #aaa;
  font-size: 0.8rem;
  margin-top: 1rem;
}

.post-hashtags {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.post-hashtags > small {
  padding-right: 0.5em;
}
/* Post- Comment Section */
.comments {
  display: flex;
  flex-direction: column-reverse;
}
.comments a {
  width: 250px;
  height: auto;
}
.comment-section > .post,
.comments > .post {
  width: 100%;
}
.comments .post button {
  width: 100px;
}

.posts-filters {
  display: flex;
  flex-direction: column;
  margin: 1em;
}

/* Spinner Styles */

.lds-ripple {
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 100px;
  height: 100px;
}
.lds-ripple div {
  position: absolute;
  border: 5px solid var(--primary-color);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 2.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* Modal */
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
.modal {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  border-radius: 5px;
  padding: 1em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.modal-bg {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.image-preview {
  max-height: 350px;
  max-width: auto;
  object-fit: contain;
}

.react-share__ShareButton {
  width: auto !important;
  margin: auto;
}

/* End of modal */

/* Social authentication buttons */
.social-auth {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.social-auth button {
  width: 33%;
  font-size: 1rem !important;
  font-weight: normal;
  padding: 0;
  border-radius: 5px !important;
  margin: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.4rem 1.3rem;
  text-transform: none;
  justify-content: center;
  text-align: center;
}

/* Default Avatars */
.default-avatars {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  /* -ms-overflow-style: none; */
}
/* .default-avatars::-webkit-scrollbar {
} */

.avatar-option {
  width: auto;
  max-height: 200px;
  object-fit: contain;
}
.avatar-option:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

/* Scrollbar */
.default-avatars::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.default-avatars::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.145);
  border-radius: 10px;
}

/* Handle */
.default-avatars::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
.default-avatars::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
  opacity: 0.8;
}

.default-avatars::-webkit-scrollbar:vertical {
  display: none;
}

/* End of default avatars */

/* Material-ui */
.MuiTouchRipple-root > *,
.MuiAutocomplete-root > *,
.MuiAutocomplete-popper {
  transition: none !important;
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"]
  .MuiAutocomplete-input:first-child {
  border: none;
  color: var(--primary-color);
}
.MuiFormLabel-root {
  color: var(--primary-color);
}
#category-filter-label,
#sorting-label {
  color: var(--primary-color);
}

/* Desktop styles */
@media screen and (min-width: 768px) {
  .btn-toggle-menu {
    display: none;
  }

  .dashboard-forms-wrapper {
    display: flex;
    /* justify-content: space-around; */
  }
  .dashboard-forms-wrapper > .post-form {
    width: 50%;
  }
  .dashboard-forms-wrapper > .post-form:last-child {
    padding-left: 1rem;
  }

  /* Footer */
  .footer {
    justify-content: center;
  }
  .footer > a {
    padding: 0 3.5em;
  }
  .footer > a > i {
    font-size: 1.75em;
  }

  /* posts */
  .posts-filters {
    flex-direction: row;
  }

  .posts-filters > div {
    width: 33%;
    margin: 1em;
  }
}

/* Mobile Styles */
@media (max-width: 767px) {
  /* responsive Navbar */
  .navbar-menu {
    position: absolute;
  }
  .navbar-menu-hidden {
    display: flex;
    flex-direction: column;
    opacity: 0;
    top: 0;
    width: 100%;
    visibility: hidden;
  }
  .navbar-menu-show {
    display: flex;
    flex-direction: column;
    height: auto;
    position: absolute;
    top: 100%;
    background: var(--dark-color);
    width: 100%;
    opacity: 1;
    /* transform: translateY(0); */
    transition: 0.5s ease-in-out;
  }
  .navbar-menu > li > a {
    font-size: 1.3rem;
  }

  /* Profile */
  .profile-top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .user-information-wrapper {
    width: 100%;
    padding: 1rem;
    justify-content: center;
    text-align: center;
  }
  .avatar-profile {
    width: 80%;
  }

  .container {
    margin-top: 8rem;
  }

  .hide-sm {
    display: none;
  }
  .show-sm {
    display: inline-block;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img {
    width: 70vw;
    margin-bottom: 1em;
  }

  .profile-grid {
    grid-template-areas:
      "top top"
      "about about"
      "posts-heading posts-heading"
      "posts posts"
      "no-memes no-memes";
  }

  .profile-about .favourite {
    flex-direction: column;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  /* Posts */
  .post {
    width: 100%;
  }

  /* Modal */
  .modal > p {
    font-size: 0.55em;
  }

  .rules {
    position: relative;
    bottom: 10px;
    width: 100%;
    padding-top: 3.75em;
    text-decoration: underline #723d84;

    column-count: 1;
  }
}
